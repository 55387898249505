import NavigationScroll from "container/layout/NavigationScroll";
import { Roles } from "helpers/enum.helpers";

import { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useAppSelector } from "store/store";
import Loader from "ui-component/Loader";
import { AuthProvider } from "./auth.provider";
import GuestRoute from "./GuestRoute";
import ProtectedUserRoute from "./ProtectedRoute";
import Reconciliation from "pages/reconciliation";
import Paymentfailed from "pages/payments/Paymentfailed";
import Paymentsuccess from "pages/payments/Paymentsuccess";

const ReconciliationList = lazy(() => import("pages/reconciliation/ReconciliationList"))

/* guest routes */
const PageNotFound = lazy(() => import("pages/pagenotfound"));
const ComingSoon = lazy(() => import("forms/hoc/coming-soon"));
const PublicPage = lazy(() => import("pages/publicPage"))
const Landing = lazy(() => import("pages/landingPage/landing"))

const Login = lazy(() => import("pages/authentication/Login"));
const LandingPage = lazy(() => import("pages/landingPage"));

const SetPassword = lazy(() => import("pages/authentication/SetPassword"));
const ForgotPassword = lazy(
  () => import("pages/authentication/ForgotPassword")
);
const OtpPage = lazy(() => import("pages/authentication/OtpPage"));

/* layout component */
const Layout = lazy(() => import("../layout/layout/MainLayout"));


const Report = lazy(() => import("pages/reports"))

const Subscription = lazy(() => import("pages/reports/SubcriptionPlan"))

const PolicyReports = lazy(() => import("pages/reports/Policy"))

const ApprovedBrokerView = lazy(() => import("pages/approvedBrokerList/ApprovedBrokerView"))

const BrokerEdit = lazy(() => import("pages/approvedBrokerList/EditBroker"));

// Pages

const RequestList = lazy(() => import("pages/brokerEnquiryList"));
const BrokerEnquiryView = lazy(() => import("pages/brokerEnquiryList/BrokerEnquiryView"))
const BrokerEnquiryEdit = lazy(() => import("pages/brokerEnquiryList/BrokerEnquiryEdit"))


const ApprovedList = lazy(() => import("pages/approvedBrokerList"));
const CreateBroker = lazy(() => import("pages/approvedBrokerList/CreateBroker"));



const BrokerEnquiry = lazy(() => import("pages/brokerEnquiry"));



const MasterList = lazy(() => import("pages/masterList"));

const Settings = lazy(() => import("pages/Settings"))

const PlanList = lazy(() => import("pages/plans"))


const FeatureList = lazy(() => import("pages/features"))


const Profile = lazy(() => import("pages/profile"));
const ResetPassword = lazy(() => import("pages/resetPassword"));

const Dashboard = lazy(() => import("pages/dashboard/index"));

const EmployeeAndLocationManagement = lazy(() => import("pages/employeeLocation/index"));

const Redirect = lazy(() => import("pages/redirect/index"));

const NotificationSection = lazy(() => import("pages/notification/index"))


const appLoader = () => {
  return <Loader />;
};



const AuthLayout = () => (
  <AuthProvider>
    <NavigationScroll>
      <Outlet />
    </NavigationScroll>
  </AuthProvider>
);

const RoutePath = createBrowserRouter(
  [
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        {
          path: "/",
          element: <GuestRoute />,
          loader: appLoader,
          children: [
            {
              path: "",
              element: <LandingPage />,
            },
            {
              path: "/subscriptionPlans",
              element: <PublicPage />
            },
            {
              path: "/login",
              element: <Login />,
            },
            {
              path: "broker_details",
              element: <BrokerEnquiry />,
            },
            {
              path: "/forgotpassword",
              element: <ForgotPassword />,
            },
            {
              path: "/payment/failed",
              element: <Paymentfailed />,
            },
            {
              path: "/payment/success",
              element: <Paymentsuccess />,
            },
            {
              path: "/redirect",
              element: <Redirect />
            }

          ],
        },
        {
          path: "/",
          element: <ProtectedUserRoute />,
          children: [
            {
              path: "",
              element: <Layout />,
              children: [
                {
                  path: "/dashboard",
                  element: <Dashboard />
                },
                {
                  path: "/enquires",
                  element: <RequestList />,
                },
                {
                  path: "/enquiry/view/:id",
                  element: <BrokerEnquiryView />,
                },
                {
                  path: "/enquiry/edit/:id",
                  element: <BrokerEnquiryEdit />,
                },
                {
                  path: "/brokers",
                  element: <ApprovedList />,
                },
                {
                  path: "/broker/create",
                  element: <CreateBroker />,
                },
                {
                  path: "/broker/view/:id",
                  element: <ApprovedBrokerView />,
                },
                {
                  path: "/broker/edit/:id",
                  element: <BrokerEdit />
                },
                {
                  path: "/reports",
                  element: <Report />,
                },
                {
                  path: "/reports/subscription-report",
                  element: <Subscription />,
                },
                {
                  path: "/reports/policy-report",
                  element: <PolicyReports />,
                },
                {
                  path: "/settings",
                  element: <Settings />,
                },
                {
                  path: "/settings/masters",
                  element: <MasterList />,
                },
                // {
                //   path:"/settings/landing",
                //   element:<Landing/>
                // },
                {
                  path: "/settings/plans",
                  element: <PlanList />,
                },
                {
                  path: "/settings/features",
                  element: <FeatureList />,
                },
                {
                  path: "/settings/employee-location-management",
                  element: <EmployeeAndLocationManagement />
                },
                {
                  path: "/profile",
                  element: <Profile />,
                },
                {
                  path: "/resetPassword",
                  element: <ResetPassword />,
                },
                {
                  path: "/coming_soon",
                  element: <ComingSoon />,
                },
                {
                  path: "/reconciliation",
                  element: <ReconciliationList />
                },
                {
                  path: "/reconciliation/upload",
                  element: <Reconciliation />
                },
                {
                  path: "/notification",
                  element: <NotificationSection />
                }

              ],
            },
          ],
        },
        {
          path: "*",
          element: <PageNotFound />,
        },
        {
          path: "404",
          element: <PageNotFound />,
        },
      ],
    }
  ],
  {
    basename: "/",
  }
);


export default RoutePath;





