// src/context/SocketContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { Socket, io } from "socket.io-client";
import { setNotiCount } from "store/slice/user.slice";
import { useAppDispatch } from "store/store";
import { useLocalStorage } from "./localstorage.helper";
import { handleSocketToast } from "forms/helpers/socket.toast";
import { toast } from "react-toastify";
import { error } from "console";

const socketUrl = `${process.env.REACT_APP_SOCKET_URL}/notifications` || "https://agency-superadmin-portal.blockchaincloudapps.com/notifications";

interface ISocketProvider {
  socket: Socket | null;
  getNotificationCount?: any;
  setSocketToken?: any;
  readMessage?: any;
}

const options = {
  type: toast.TYPE.WARNING,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_RIGHT,
  pauseOnHover: true,
  progress: undefined,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  autoClose: 5000,
};


const SocketContext = createContext<ISocketProvider>({ socket: null });

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }: any) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const dispatch = useAppDispatch();
  const [token, setToken] = useLocalStorage("token", null);

  const handleUserConnection = (e: any) => {
    console.log("User Joined");
  }

  const handleSocket = () => {
    try {
      const connection = io(socketUrl, {
        path: "/api/socket/notifications",
        transports: ["websocket", "polling"],
        auth: { token },
      });
      connection.on("connect", () => {
        console.log("Socket Connected")
        setSocket(connection);
        if (token) {
          connection?.emit("unread-notifications")
        }
      });

      connection.on("disconnect", () => {
        console.log("Socket disconnected!");
        setSocket(null);
      });

      connection.on("notifications-count", (data) => {
        console.log(data?.count)
        dispatch(setNotiCount(data?.count));
      })

      connection.on("update-notification-count", (data) => {
        console.log(data?.count)
        dispatch(setNotiCount(data?.count));
      })

      connection.on("notification-message", (message: string) => {
        if (localStorage.getItem('token')) {
          const success = { ...options, type: toast.TYPE.SUCCESS };
          toast.success(message, { ...success, theme: "light" });
        }
      })

      connection.on("error", (error) => {
        console.log("error", error)
        // console.log(error)
      })

      return () => {
        if (socket) {
          socket?.disconnect();
        }
      };
    } catch (error) {
      console.log("error", error)
      // console.log(error)
    }
  }

  useEffect(() => {
    // const success = { ...options, type: toast.TYPE.SUCCESS };
    // if (token) {
    //   toast.success("message", { ...success, theme: "light" });
    // }
    handleSocket()
  }, [token]);


  const handleNotification = (count: { count: number }) => {
    dispatch(setNotiCount(count?.count));
  };

  const getNotificationCount = () => {
    if (socket) {
      socket?.emit("notification-count", handleNotification);
    }
  };

  const readMessage = () => {
    if (socket) {
      socket?.emit("read-notification");
    }
  };

  const setSocketToken = (token: string = "") => {
    if (socket) {
      socket.disconnect(); //This uses the same socket and disconnect with the server.
      socket.auth = {
        token,
      };
      socket.open(); //Opens up a new connection with `x-auth-token` in headers
    }
  };

  return (
    <SocketContext.Provider
      value={{ socket: socket, getNotificationCount, setSocketToken, readMessage }}
    >
      {children}
    </SocketContext.Provider>
  );
};
